import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, CloseButton } from './basics'
import { rgba, RED } from '../constants/styles'
import { isMobile } from 'react-device-detect'
import config from '../config'
import { useAutoFullscreenOnFirstClick } from '../utility/hooks.jsx'

const StyledTakeOff = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	background-color: ${rgba(RED, 0.5)};
	z-index: 1000;
`

const Centered = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	height: 100%;
`

export default function GoFullHeader(): React$Node {
	const {
		hasTriggered,
		canGoFullscreen,
		isFullscreen,
		enterFullscreen,
	} = useAutoFullscreenOnFirstClick()
	const [visible, setVisible] = useState(!isMobile)
	if (config.devFlags?.hideFullscreenHeader === true || !canGoFullscreen) {
		return null
	}

	return (
		visible &&
		!isFullscreen && (
			<StyledTakeOff>
				<Centered>
					{hasTriggered ? (
						<>
							<h3>These controls are best in</h3>
							<Button className="m-2" onClick={() => enterFullscreen()}>
								full screen
							</Button>
						</>
					) : (
						<h3 className="p-3">Click anywhere to enter full screen</h3>
					)}
				</Centered>
				<CloseButton onClick={() => setVisible(false)} />
			</StyledTakeOff>
		)
	)
}
