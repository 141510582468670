// @flow
import { useEffect, useMemo, useRef, useState, useCallback, useLayoutEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector, useDispatch } from 'react-redux'
import { useInterval } from 'use-interval'
import uuid from 'uuid/v4'
import fscreen from 'fscreen'
import { isMobile } from 'react-device-detect'
import { IS_WEBKIT } from '../constants/browser'
import { GOOGLE_TRANSLATE_URL } from '../constants/translationConstants'
import {
	getMyServerThrustersDirection,
	isThrustersActive,
} from '../store/selectors/sharedSelectors'
import { THRUSTERS_POINT_LOCATIONS, THRUSTERS_SEND_UPDATE_AFTER } from '../constants'
import { changeDirection } from '../store/stores/thrusters'
import { areDirectionsDifferentEnoughToSend } from './functions'
import type { Direction } from '@mission.io/mission-toolkit'
import { FRAME_WRAPPER_ID } from '../components/basics/ReactModal'
import { NoSleep } from '../localModules/noSleep'
import { sendMessage } from '../websockets/websocket'
import { isTraining as getIsTraining } from '../store/stores/general.js'
import config from '../config'
/*
 * A hook that returns a unique id
 */
export function useUniqueId(): string {
	const id = useMemo(() => {
		return uuid()
	}, [])
	return id
}

export function useEffectAfterFirstRender(effect: () => void | (() => void), deps: mixed[]) {
	const firstRender = useRef(true)
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false
			return
		}
		return effect()

		// dependencies are passed in from function call which linter can not follow
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps)
}

/**
 * A hook that provides the current dimensions of the station container, aka the area where the station is displayed. For a student, this is the entire window.
 * For the teacher station, this only the section where the game is displayed.
 */
export function useStationContainerDimensions(): null | {
	width: number,
	height: number,
	top: number,
	left: number,
} {
	const [dimensions, setDimensions] = useState(() => {
		const stationContainer = document.getElementById(FRAME_WRAPPER_ID)

		return stationContainer
			? {
					width: stationContainer.clientWidth,
					height: stationContainer.clientHeight,
					top: stationContainer.getBoundingClientRect().top,
					left: stationContainer.getBoundingClientRect().left,
			  }
			: null
	})

	useLayoutEffect(() => {
		const handleResize = () => {
			const stationContainer = document.getElementById(FRAME_WRAPPER_ID)
			setDimensions(
				stationContainer
					? {
							width: stationContainer.clientWidth,
							height: stationContainer.clientHeight,
							top: stationContainer.getBoundingClientRect().top,
							left: stationContainer.getBoundingClientRect().left,
					  }
					: null
			)
		}
		handleResize()

		window.addEventListener('resize', handleResize)

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return dimensions
}

export function useRefResize(ref: {
	current: ?(HTMLHtmlElement | Element),
}): { width: number, height: number } {
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [, setForceUpdate] = useState(false)

	const updateHeightAndWidth = useCallback(() => {
		const htmlElement = ref.current
		if (!htmlElement) {
			return
		}
		const rect = htmlElement.getBoundingClientRect()
		setWidth(rect.width)
		setHeight(rect.height)
	}, [setWidth, setHeight, ref])

	useEffect(() => {
		// force this hook to rerender on the second frame so that the ref.current exists
		setForceUpdate(true)
	}, [])

	const currentRef = ref.current

	useEffect(() => {
		if (currentRef) {
			updateHeightAndWidth()
		}
	}, [currentRef, updateHeightAndWidth])

	useEffect(() => {
		window.addEventListener('resize', updateHeightAndWidth)
		return () => {
			window.removeEventListener('resize', updateHeightAndWidth)
		}
	}, [updateHeightAndWidth])

	// webkit hack: 'resize' event is called before the dom element is actually resized
	useLayoutEffect(() => {
		if (!IS_WEBKIT) {
			return
		}
		const htmlElement = ref.current
		if (!htmlElement || !(htmlElement.clientWidth - width || htmlElement.clientHeight - height)) {
			return
		}
		updateHeightAndWidth()
	})

	return { width, height }
}

// function to help check previous state
export function usePrevious<U>(value: U): ?U {
	const ref = useRef<?U>()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

/**
 * Works similar to setTimeout but runs the most up to date callback function and clears the timeout when the calling
component unmounts
@param {any => void} callback the callback function to call after the timeout
@param {number} delay the amount of time in milliseconds before the callback function will be called.
*/
export function useTimeout(callback: any => void, delay: number) {
	const savedCallback = useRef()
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])
	useEffect(() => {
		const handler = (...args: any[]) => savedCallback.current && savedCallback.current(...args)
		const timeout = setTimeout(handler, delay)
		return () => clearTimeout(timeout)
	}, [delay])
}

/**
 * A hook that causes an event to be fired when a mouse/touch event happens outside of a react component.
 * Example:
 * const modalRef = useOnClickOutside(closeModal)
 * return <Modal ref={modalRef}>I am a modal! I will close if you click outside of me.</Modal>
 * @param {() => void} callback  The callback to be called when the event fires.
 * @return {{current: ?HTMLElement}} a ref for the element where the callback is called anytime
 * there is a mouse click outside of that component
 */
export function useOnClickOutside(callback: Node => void): { current: ?HTMLElement } {
	const node = useRef<?HTMLElement>()
	const savedCallbackRef = useRef()

	useEffect(() => {
		savedCallbackRef.current = callback
	}, [callback])

	useEffect(() => {
		const handleClick = (event: TouchEvent | MouseEvent) => {
			if (
				savedCallbackRef.current &&
				event.target instanceof Node &&
				node.current &&
				// Make sure the event did not happen inside the current node
				!node.current.contains(event.target)
			) {
				savedCallbackRef.current(event.target)
			}
		}
		// add when mounted
		document.addEventListener('mousedown', handleClick)
		document.addEventListener('touchstart', handleClick)
		// return function to be called when unmounted
		return () => {
			document.removeEventListener('mousedown', handleClick)
			document.removeEventListener('touchstart', handleClick)
		}
	}, [])
	return node
}

/**
 * A function that removed the google translate element before reload
 * @return
 */
export function removeGoogleTranslate(): void {
	const scriptElements = ((document.getElementsByTagName('script'): any): HTMLCollection<any>)
	Array.from(scriptElements).forEach(function(script) {
		if (script.getAttribute('src') === GOOGLE_TRANSLATE_URL) {
			script.parentNode.removeChild(script)
		}
	})

	const googleElement = ((document.getElementById('goog-gt-tt'): any): HTMLElement)
	if (googleElement) googleElement.remove()

	const elements = (document.getElementsByClassName('goog-te-spinner-pos'): HTMLCollection<any>)
	while (elements.length > 0) {
		elements[0].parentNode.removeChild(elements[0])
	}

	const iframeElements = ((document.querySelectorAll('iframe'): any): HTMLCollection<any>)
	Array.from(iframeElements).forEach(function(elem) {
		if (elem.getAttribute('class') === 'goog-te-menu-frame skiptranslate') {
			elem.parentNode.removeChild(elem)
		}
	})
}

const cachedScripts = {}
/**
 * useScript hook copied from https://usehooks.com/useScript/ and modified to use promises to avoid race conditions
 * Dynamically loads a `script` tag using the given url as the src attribute. When the script is loaded, loaded will be set to true
 * @return {[loaded, error]} Two booleans that tell whether the script loaded or if there was an error
 */
export function useScript(
	src: string,
	reload: boolean,
	beforeReload: () => void
): [boolean, boolean] {
	// Keeping track of script loaded and error state
	const [state, setState] = useState({
		loaded: false,
		error: false,
	})

	useEffect(
		() => {
			// If cachedScripts already includes src that means another instance ...
			// ... of this hook already loaded this script, so no need to load again.
			// just wait for the existing promise to resolve
			if (cachedScripts[src] && !reload) {
				cachedScripts[src]
					.then(() => {
						setState({
							loaded: true,
							error: false,
						})
					})
					.catch(e => {
						setState({ loaded: true, error: true })
						console.error(e)
					})
			} else {
				// Before reload
				if (reload) {
					beforeReload()
				}

				// Create script
				let script = document.createElement('script')
				script.src = src
				script.async = true

				// resolve and reject for the created promise need to be stored here so they can be referenced inside of the handlers `onScriptLoad`
				// and `onScriptError`. We can't define the handlers inside of the promise because then we couldn't clean them up in the cleanup function for the useEffect
				const promiseFunctions = {
					resolve: () => {},
					reject: () => {},
				}
				// Script event listener callbacks for load and error
				const onScriptLoad = () => {
					setState({
						loaded: true,
						error: false,
					})
					promiseFunctions.resolve()
				}

				const onScriptError = () => {
					// Remove from cachedScripts we can try loading again
					if (src in cachedScripts) delete cachedScripts[src]
					script.remove()

					setState({
						loaded: true,
						error: true,
					})
					promiseFunctions.reject()
				}

				cachedScripts[src] = new Promise((resolve, reject) => {
					// Store resolve and reject in `promiseFunctions` so they can be used outside of the promise
					promiseFunctions.resolve = resolve
					promiseFunctions.reject = reject
					script.addEventListener('load', onScriptLoad)
					script.addEventListener('error', onScriptError)

					// Add script to document body
					// $FlowFixMe document.body is always defined in our app
					document.body.appendChild(script)
				})

				// Remove event listeners on cleanup
				return () => {
					script.removeEventListener('load', onScriptLoad)
					script.removeEventListener('error', onScriptError)
				}
			}
		},
		[src, beforeReload, reload] // Only re-run effect if script src changes
	)

	return [state.loaded, state.error]
}

export type GoogleTranslate = {
	loaded: boolean,
}

/**
 * Loads the Google translate so that it's ready to use
 * @return {obj.loaded} Whether the google translate is ready to use
 */
export function useGoogleTranslate(): GoogleTranslate {
	useEffect(() => {
		window.googleTranslateElementInit ??= () => {
			// eslint-disable-next-line no-new
			new window.google.translate.TranslateElement(
				{
					pageLanguage: 'en',
					includedLanguages: 'en,es',
					multilanguagePage: true,
				},
				'google_translate_element'
			)
		}
	}, [])

	// window.googleTranslateElementInit must be set before the script finishes loading
	const [scriptLoaded] = useScript(GOOGLE_TRANSLATE_URL, true, removeGoogleTranslate)

	return { loaded: scriptLoaded }
}

/**
 * useThrottledUpdateDirectionChange - return a function used to update the student's direction on the client and server.
 * The most recent value will only be sent to the server every `THRUSTERS_SEND_UPDATE_AFTER`, and then only if
 * the new direction is different enough from the student's current direction on the server to warrant an update.
 *
 * @return (direction: ?Direction) => void - callback used to update the location of the joystick
 */
export function useThrottledUpdateDirectionChange(): (direction: ?Direction) => void {
	const directionToSend = useRef(null)

	const isActive = useSelector(isThrustersActive)
	const myServerDirection = useSelector(getMyServerThrustersDirection)
	const dispatch = useDispatch()

	const nextPointSpawnLocationIndex = useRef(0)

	useInterval(
		() => {
			// update direction on server
			const myDirection = directionToSend.current
			if (!isActive || !areDirectionsDifferentEnoughToSend(myDirection, myServerDirection)) {
				return
			}

			const locationIndex = nextPointSpawnLocationIndex.current
			nextPointSpawnLocationIndex.current = (locationIndex + 1) % THRUSTERS_POINT_LOCATIONS.length

			sendMessage(
				'THRUSTERS',
				{ direction: myDirection },
				{ location: THRUSTERS_POINT_LOCATIONS[locationIndex] }
			)
		},
		isActive ? THRUSTERS_SEND_UPDATE_AFTER : null
	)

	return (direction: ?Direction) => {
		dispatch(changeDirection(direction))
		directionToSend.current = direction
	}
}

// Taken from https://github.com/malcolm-kee/react-no-sleep#readme
/**
 * useNoSleep - will stop the screen from sleeping if `enabled` is true, will allow for sleeping in `enabled` is false.
 *
 * @param {boolean} enabled - true if the screen should not be allowed to sleep, false otherwise
 *
 */
export function useNoSleep(enabled: boolean) {
	const noSleep = useMemo(() => new NoSleep(), [])

	useEffect(() => {
		if (enabled) {
			const enableNoSleep = () => {
				noSleep.enable().catch(error => {
					console.error(error)
				})
			}

			document.addEventListener('click', enableNoSleep, { capture: false, once: true })
			return () => document.removeEventListener('click', enableNoSleep)
		} else {
			noSleep.disable()
		}
	}, [enabled, noSleep])
}

/**
 * usePageIsVisible - monitors the visibility state of the page
 *
 * @return {boolean} - true if the page is visible, false otherwise
 */
export function usePageIsVisible(): boolean {
	const [isPageVisible, setIsPageVisible] = useState(document.visibilityState === 'visible')

	useEffect(() => {
		const updatePageVisibility = () => setIsPageVisible(document.visibilityState === 'visible')
		document.addEventListener('visibilitychange', updatePageVisibility)
		return () => {
			document.removeEventListener('visibilitychange', updatePageVisibility)
		}
	}, [])

	return document.visibilityState !== undefined ? isPageVisible : true
}

// Updated from from https://usehooks.com/useDebounce/
export function useDebounce<T>(value: T, delay: number): T {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value)
	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value)
			}, delay)
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler)
			}
		},
		[value, delay] // Only re-call effect if value or delay changes
	)
	return debouncedValue
}

type Dimensions = {
	x: number | null,
	y: number | null,
	width: number | null,
	height: number | null,
}

/**
 * Get the dimensions of an object
 *
 * @returns a ref to attach to an element, and the dimensions of the element
 */
export function useDimensions<T: HTMLElement>(): [{ current: ?T }, Dimensions] {
	const ref = useRef<?T>(null)
	const [dimensions, setDimensions] = useState<Dimensions>({
		x: null,
		y: null,
		width: null,
		height: null,
	})

	useLayoutEffect(() => {
		function updateDimensions() {
			const updateDimensions = ref.current?.getBoundingClientRect()
			if (!updateDimensions) {
				return
			}
			setDimensions({
				x: updateDimensions.left,
				y: updateDimensions.top,
				width: updateDimensions.width,
				height: updateDimensions.height,
			})
		}

		updateDimensions()

		window.addEventListener('resize', updateDimensions)
		return () => {
			window.removeEventListener('resize', updateDimensions)
		}
	}, [])

	return [ref, dimensions]
}

/**
 * Gets the dimensions of the window.
 * Copied from https://usehooks.com/useWindowSize/
 */
export function useWindowWidth(): number {
	const [windowWidth, setWindowWidth] = useState(
		document.documentElement?.clientWidth || window.innerWidth
	)

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			const width = document.documentElement?.clientWidth || window.innerWidth
			setWindowWidth(width)
		}
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowWidth
}

/**
 * A hook that obtains the width of an html image element after an image has been loaded or after a screen resize occurs.
 * @returns {[React.MutableRefObject<HTMLImageElement | null>, number]} A tuple containing a ref to the image element and the width of the image.
 */
export function useImageWidth(): [{ current: HTMLImageElement | null }, number] {
	const ref = useRef<HTMLImageElement | null>(null)
	const [width, setWidth] = useState(0)

	useLayoutEffect(() => {
		function updateWidth() {
			const updateDimensions = ref.current?.getBoundingClientRect()
			if (!updateDimensions) {
				return
			}
			setWidth(updateDimensions.width)
		}
		const imageElem = ref.current
		updateWidth()
		if (imageElem) {
			imageElem.addEventListener('load', updateWidth)
		}
		window.addEventListener('resize', updateWidth)
		return () => {
			window.removeEventListener('resize', updateWidth)
			if (imageElem) {
				imageElem.removeEventListener('load', updateWidth)
			}
		}
	}, [])

	return [ref, width]
}

/**
 * Extracts the value of a URL param with the given `paramName` and passes it to the `callback` function. As soon as the value is
 * consumed, it is removed from the URL. Therefore, when a param is added to the URL, `callback` will only be called with that value once.
 *
 * @param paramName - the name of the URL param to consume
 * @param callback - the function to call with the value of the URL param
 */
export function useConsumeParam(paramName: string, callback: (value: string) => mixed) {
	const url = new URL(window.location.href)
	const value = url.searchParams.get(paramName)
	if (value != null) {
		callback(value)
		// remove the param from the URL
		url.searchParams.delete(paramName)
		window.history.replaceState(window.history.state, '', url.toString())
	}
}

/**
 * A hook that returns the dimensions of the screen.
 */
export function useScreenDimensions(): { width: number, height: number } {
	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	useEffect(() => {
		const handleResize = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return dimensions
}

/**
 * A hook that enters fullscreen mode when the user clicks anywhere on the page the first time.
 *
 * The hook will only enter fullscreen mode if the user is not already in fullscreen mode,
 * if the user is not on a mobile device, and if the user is not in training mode.
 *
 * @return an object with the following properties:
 * - `hasTriggered`: a boolean indicating whether the user has triggered fullscreen mode.
 * - `isFullscreen`: a boolean indicating whether the user is currently in fullscreen mode.
 * - `canGoFullscreen`: a boolean indicating whether the user can enter fullscreen mode.
 * - `exitFullscreen`: a function that exits fullscreen mode.
 * - `enterFullscreen`: a function that enters fullscreen mode.
 */
export const useAutoFullscreenOnFirstClick = (): ({
	hasTriggered: boolean,
	canGoFullscreen: boolean,
	isFullscreen: boolean,
	exitFullscreen: () => void,
	enterFullscreen: () => void,
}) => {
	const [isFullscreen, setIsFullscreen] = useState(!!fscreen.fullscreenElement)
	const [hasTriggered, setHasTriggered] = useState(false)

	const isTraining = useSelector(getIsTraining)

	const canGoFullscreen = !isFullscreen && !isTraining && !isMobile

	const enterFullscreen = useCallback(() => {
		if (!fscreen.fullscreenElement && canGoFullscreen) {
			fscreen.requestFullscreen(document.documentElement)
			setHasTriggered(true)
		}
	}, [canGoFullscreen])

	const exitFullscreen = useCallback(() => {
		if (fscreen.fullscreenElement) {
			fscreen.exitFullscreen()
		}
	}, [])
	useEffect(() => {
		if (config.devFlags?.hideFullscreenHeader || isMobile) return
		const handleFullscreenChange = () => {
			setIsFullscreen(!!fscreen.fullscreenElement)
		}

		const handleFirstClick = () => {
			if (!hasTriggered) {
				enterFullscreen()
			}
		}

		document.addEventListener('fullscreenchange', handleFullscreenChange)
		document.addEventListener('click', handleFirstClick, { once: true }) // only first click

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange)
			document.removeEventListener('click', handleFirstClick)
		}
	}, [enterFullscreen, hasTriggered])

	return { hasTriggered, canGoFullscreen, isFullscreen, exitFullscreen, enterFullscreen }
}

/**
 * A hook that checks if an element is visible in the viewport and not obscured by other elements.
 * It uses the Intersection Observer API to detect visibility and checks for obstructions using elementFromPoint every 500 ms.
 * @param {function} _onVisibilityChange - A callback function that is called when the visibility changes.
 * 		It receives two arguments: the new visibility state and the reason for the change.
 * @param {boolean} cancel - An optional flag to cancel the visibility check interval, by default it is false. The interval only stops when the ref is null.
 *
 * @returns {ref: (node?: Element | null) => void, isVisible: boolean} - A ref to attach to the element and a boolean indicating visibility.
 * */
export function useVisibilityCheck({
	onVisibilityChange: _onVisibilityChange,
	cancel = false,
}: {|
	onVisibilityChange?: (boolean, reason: string) => void,
	cancel?: boolean,
|}): { ref: (node?: Element | null) => void, isVisible: boolean } {
	const ref = useRef()
	const { ref: inViewRef, inView } = useInView({ threshold: 0.1 })
	const setRefs = useCallback(
		node => {
			ref.current = node
			inViewRef(node)
		},
		[inViewRef]
	)
	const [isVisible, _setVisibility] = useState(false)
	const onVisibilityChange = useRef(_onVisibilityChange)

	useEffect(() => {
		onVisibilityChange.current = _onVisibilityChange
	}, [_onVisibilityChange])
	const [unmountedCancel, setUnMountedCancel] = useState(false)

	// Allow for a side effect when setting the visibility, call onVisibilityChange
	const setVisibility = useCallback((newVisibilityState: boolean, status: string) => {
		_setVisibility(pastVisibilityState => {
			if (pastVisibilityState === true && status === 'unmounted') {
				setUnMountedCancel(true)
			}
			if (pastVisibilityState !== newVisibilityState) {
				onVisibilityChange.current?.(newVisibilityState, status)
			}
			return newVisibilityState
		})
	}, [])

	// Checks if the current ref is visible or not.
	useInterval(
		() => {
			if (!ref.current) {
				// Technically this runs on the first render, but since the initial visibility state is already false, nothing changes
				setVisibility(false, 'unmounted')
				return
			}
			if (!inView) {
				setVisibility(false, 'scrolledAway')
				return
			}
			const currentlyObscured = HELPERS.isCurrentlyObscured(ref)
			setVisibility(!currentlyObscured, currentlyObscured ? 'obscured' : 'visible')
		},
		cancel || unmountedCancel ? null : 500,
		true
	)

	return { ref: setRefs, isVisible }
}

// In order to spy on this helper function we need to export HELPERS
export const HELPERS = {
	// Given a reference to an element in the DOM, checks to see if that element's center point is obscured by another element.
	isCurrentlyObscured: (ref: { current: ?Element }): boolean => {
		if (!ref.current) {
			return false
		}
		const { top, left, width, height } = ref.current.getBoundingClientRect()
		const midX = left + width / 2
		const midY = top + height / 2
		const elementAtPoint = document.elementFromPoint(midX, midY)
		return Boolean(elementAtPoint && !ref.current?.contains(elementAtPoint))
	},
}
